import "./App.css";
import "./assets/css/bootstrap.css";
import "./assets/css/maicons.css";
import "./assets/css/theme.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { lazy, Suspense } from "react";

const HomePage = lazy(() => import("./Pages/Home"));
const AboutPage = lazy(() => import("./Pages/About"));
const ReachOutUsPage = lazy(() => import("./Pages/ReactOutUs"));
const ServicesPage = lazy(() => import("./Pages/Services"));
const BlogPage = lazy(() => import("./Pages/Blog"));
const BlogDetails = lazy(() => import("./Pages/BlogDetails"));
const Header = lazy(() => import("./Components/Header"));
const Footer = lazy(() => import("./Components/Footer/Footer"));

function App() {
  return (
     <Router>
     <Suspense fallback={<div>Loading...</div>}>
       <Header />
      <Routes>

        <Route  path="/" element={<HomePage />}></Route>
        <Route  path="/about" element={<AboutPage />}></Route>
        <Route  path="/reach-out-us" element={<ReachOutUsPage />}></Route>
        <Route  path="/services" element={<ServicesPage />}></Route>
        <Route  path="/blog" element={<BlogPage />}></Route>
        <Route  path="/blogDetails" element={<BlogDetails />}></Route>
      </Routes>  
      <Footer />  
     </Suspense>
   </Router>
  );
}

export default App;
